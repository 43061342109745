<template>
    <div style="width:100%;height:100%">
        <div>
            <button @click="connent">连接2</button>
            <div>连接状态: {{ conn_state }}</div>
            <button @click="disConnent">断开</button>

            <button @click="initRoom">初始化房间</button>
            <div>房间状态: {{ room_state }}</div>
            <button @click="createRoom">创建房间</button>
            <span>room_id: {{ room_id }} ，rtoken {{ rtoken }}</span>
            <button @click="joinRoom">进入房间</button>
        </div>

        <div id="videos">
            <div class="publishVideo">
                <video id="publish_video1" autoplay muted width="100%" height="100%" webkit-playsinline="true" playsinline="true" style="object-fit: cover;">
                    video
                </video>
                <!-- <video id="publish_video1" autoplay muted="true" webkit-playsinline="true" playsinline="true" style="object-fit: cover;"></video> -->
                <label id="publish_media_stat1" class="css-text-color hiddenForMobile" type="text"></label>
                <label type="text" id="streamId_text" class="hiddenForMobile">streamId:</label>
                <label id="publish_streamId1" class="css-text-color hiddenForMobile" type="text"></label>
            </div>
            <div class="subscribeVideo">
                <video id="video0" autoplay muted width="100%" height="100%" webkit-playsinline="true" playsinline="true" style="object-fit: cover;">
                    video
                </video>
                <audio id="audio0" autoplay>音频</audio>

                <br />
                <label type="text" id="subscribe_feedId_text0" class="hiddenForMobile" hidden>&nbsp;&nbsp;feedId:</label>
                <label id="feedId0" class="css-text-color hiddenForMobile" type="text"></label>
                <br />
                <label type="text" id="subscribe_streamId_text0" hidden class="hiddenForMobile">&nbsp;&nbsp;streamId:</label>
                <label id="subscribe_streamId0" class="css-text-color hiddenForMobile" type="text"></label>
                <button @click="leaveRoom">离开</button>
            </div>
        </div>
    </div>
</template>

<script>
// import './lib/adapter';
// import MeetingController from './lib/meeting_api.js';
// import './lib/remote_record.js';

// import './lib/getMediaInfo';
// import './lib/eruda.js';
// import './lib/meeting_camera_stream.js';

// import './lib/meeting_html_stream';
// import './lib/meeting_im.js';
// import './lib/meeting_file_stream.js';
// import './lib/meeting_vod';
// import './lib/meeting_invite.js';

// import DeskStreamController from './lib/meeting_desk_stream.js';
// import HtmlStreamController from './lib/meeting_html_stream.js';
// import FileStreamController from './lib/meeting_file_stream.js';
// import MeetingIMController from './lib/meeting_im.js';
// import MeetingVodController from './lib/meeting_vod.js';
// import MeetingInviteController from './lib/meeting_invite.js';
// import ClientRecordController from './lib/client_record.js';
import RemoteRecordController from './lib/remote_record.js';
import CameraStreamController from './lib/meeting_camera_stream.js';
import MeetingController from './lib/meeting_api.js';
import './lib/adapter.js';

// window.DeskStreamController = DeskStreamController;
// window.FileStreamController = FileStreamController;
// window.HtmlStreamController = HtmlStreamController;
// window.meetingBeautifyStream = meetingBeautifyStream;
// window.MeetingIMController = MeetingIMController;
// window.MeetingInviteController = MeetingInviteController;
// window.ClientRecordController = ClientRecordController;

// window.Adapter = Adapter;
// window.MeetingVodController = MeetingVodController;

export default {
    data() {
        return {
            conn_state: 0,
            //0 未初始化， 1 已初始化， 2 已连接， 3 已断开
            room_state: 0,
            room_id: '721200726101031',
            rtoken: 'cbfb4333b10e',
            sign:
                'bFtSUuSK64CxcZe7bvbkDBNaEwCWb/2711wc+JjnhfAw5iJYid5EoxvUYa5HmP97NF8ml0+mHVskHNpbXycnVT2NM/u+9Ac/8ZeE8cRBhEYqFKpc+A6+oPqoDF0/xZ43lSIoWAOp0st8XblqgPOcKvevXbVb4gBzaLDe/WTdlsQvMfxp1KZlSLl+dbrca945v3zRsWI1t+gwNo8e04p2temjecPzJJnLxRcJppNhrYfRvQO+BnsQ1oT/COlSxitFlnOUCZyzCfhqrJ4H/6kjnag2Vw1SuJA3WKNJ71AlrWQ3iBMGpYlos4RY+3eW60SmdBo2UoQ8N70l7YaX+LLpfA=='
        };
    },
    mounted() {
        window.RemoteRecordController = RemoteRecordController;
        window.CameraStreamController = CameraStreamController;
        window.MeetingController = MeetingController;
        this.meetingCtrl = new MeetingController();
        this.meetingCtrl.OnConnectFailed = (code, msg) => {
            this.conn_state = 0;
            console.log('meetingCtrl：连接失败', code, msg);
        };

        //建立连接成功回调
        this.meetingCtrl.OnConnectOK = () => {
            this.conn_state = 1;
            console.log('meetingCtrl：连接服务器成功');
        };

        //断开连接回调
        this.meetingCtrl.OnConnectClose = () => {
            console.log('meetingCtrl：断开连接');
            this.conn_state = 0;
        };

        this.meetingCtrl.OnInitRoomConfigOK = () => {
            this.room_state = 1;
            // this.createRoom();
            console.log('meetingCtrl：房间初始化成功');
        };

        this.meetingCtrl.OnInitRoomConfigFail = (code, msg) => {
            this.room_state = 0;
            console.log('meetingCtrl：房间初始化失败', code, msg);
        };
        this.meetingCtrl.OnCreateRoomSucc = (room_id, rtoken) => {
            this.room_id = room_id;
            this.rtoken = rtoken;
            // this.room_state = 2;
            console.log('meetingCtrl：创建房间成功', room_id, rtoken);
        };

        this.meetingCtrl.OnCreateRoomFailed = (code, msg) => {
            this.room_state = 1;
            console.log('meetingCtrl：创建房间失败', code, msg);
        };

        this.meetingCtrl.OnJoinRoomSucc = () => {
            // this.room_id = room_id;
            // this.rtoken = rtoken;
            this.room_state = 2;
            console.log('meetingCtrl：加入房间成功');
        };

        this.meetingCtrl.OnJoinRoomFailed = (code, msg) => {
            this.room_state = 1;
            console.log('meetingCtrl：加入房间失败', code, msg);
        };

        this.meetingCtrl.OnLeaveRoom = leaveType => {
            this.room_state = 3;
            console.log('meetingCtrl：离开房间', leaveType);
        };

        this.meetingCtrl.OnMediaCallSucc = () => {
            console.log('meetingCtrl：OnMediaCallSucc', leaveType);
        };

        this.meetingCtrl.OnMediaCallFail = (err_code, err_msg) => {
            console.log('meetingCtrl：OnMediaCallFail', err_code, err_msg);
        };
    },

    //销毁
    beforeDestroy() {
        this.meetingCtrl.Disconnect();
        this.meetingCtrl = null;
        window.RemoteRecordController = null;
        window.CameraStreamController = null;
        window.MeetingController = null;
    },

    methods: {
        init() {},
        connent() {
            let config_param = {};
            config_param.uid = 8888;
            config_param.biz_name = '5D57B94763995186E5B7160DE7FF5109';
            config_param.appId = 'ALIPUB30C48C4091547';
            config_param.sub_biz = 'ALIPUB30C48C4091547';
            config_param.workspaceId = 'product';
            config_param.room_server_url = 'wss://mrtc.mpaas.cn-hangzhou.aliyuncs.com/ws';
            config_param.sign =
                'QCZqi4oV+kuQ/3RF84HA1RFoiSVvv3Sa+kpDL6YISDNyLaAMHu5x3rB6gg+xwapAcQ3IL3TIGVkxnI84bl/GudNEtr9xHArAnqtQZ0IGpZUuBtWfwneBvFC+QZ/sEvQAoYH/NKkrKd0BjH7FEvc9528a45lRENEg2YLXuHM+0SP//WlzzeB80gXPG20es/wowI43JvKs5qljP8DLMrUWPQBoPR2WQerKyeVjwKcrc0+CEvXveQSWqYPArNldxmpVn5qZ943gbsFbGw0qe4bE65DnaVbvRW/SUc48GuBtu/Go1CX2rGEVvRk5gmLigmrROmMk48e5JFx29FO1/Ql3iw==';

            // 允许最大断网时间 (超过未重连, 直接关闭)
            config_param.network_check_timeout = 10000;
            this.meetingCtrl.Connect(config_param);
        },
        disConnent() {
            this.meetingCtrl.Disconnect();
        },
        initRoom() {
            let config_param = {};
            config_param.auto_publish_subscribe = 3;
            /**
             * 音视频类型：
                1：音视频

                2：纯音频

                3：纯视频
             */
            config_param.media_type = 1;
            //发布类型：
            config_param.publish_device = 1;

            //订阅参数
            config_param.initSubscribe = [
                {
                    subscribe_video_id: 'video0',
                    subscribe_audio_id: 'audio0',
                    subscribe_streamId_id: 'subscribe_streamId0',
                    feedId_id: 'feedId0'
                }
            ];
            //当 publish_device 值为 1 时，需要配置以下参数：

            //本地视频清晰度档位：
            // config_param.video_profile_type = 4;
            config_param.initPublish = [{ publish_video_id: 'publish_video1', publish_streamId_id: 'publish_streamId1', publish_tag: 'tag1' }];

            //推送模式，使用直播模式时必填。2：RTC 模式3：直播模式
            config_param.publish_type = 2;
            //服务端是否默认录制，默认为 false。
            // config_param.defaultRecord = true;

            this.meetingCtrl.InitRoomConfig(config_param);

            // config_param.publish_bitrate =
        },
        createRoom() {
            this.meetingCtrl.CreateRoom(this.sign);
        },
        joinRoom() {
            this.meetingCtrl.JoinRoom(this.room_id, this.rtoken, this.sign);
        },

        leaveRoom() {
            this.meetingCtrl.LeaveRoom();
        }
    }
};
</script>

<style>
#app {
    width: 100%;
    height: 100%;
}
</style>

<style>
#videos {
    position: relative;

    width: 100%;
    height: 100%;
}

.publishVideo {
    /* position: absolute; */
    height: 100%;
    width: 100%;
}

.publishVideo.side {
    width: 30%;
    height: 30%;
    z-index: 99;
    top: 0;
    left: 0;
}

#room_id {
    width: 50%;
}

#rtoken {
    margin-top: 5px;
}

#rtokenLabel {
    margin-top: 5px;
}

#publish_video1 {
    height: 100%;
    width: 100%;
}

.subscribeVideo {
    /* position: absolute; */
    height: 100%;
    width: 100%;
}

.subscribeVideo.side {
    width: 30%;
    height: 30%;
    z-index: 99;
    top: 0;
    left: 0;
}

.icons-m {
    position: absolute;
    bottom: 120px;
    left: 0;
    margin: 0;
    z-index: 999;
}

.roomInfo {
    position: absolute;
    bottom: 20px;
    left: 0px;
    z-index: 100;
}

.roomInfo span {
    background-color: transparent;
    border: none;
    color: whitesmoke;
    font-size: 16px;
}

#icons1 {
    margin-left: 20px;
    margin-top: 10px;
}

#icons1.active svg {
    transform: translateX(0);
}

#icons2 {
    position: absolute;
    right: 20px;
    bottom: 30px;
    width: 50px;
    height: 50px;
}

#invitee {
    height: 20px;
    width: 140px;
}

.info {
    padding-left: 30px;
}
</style>
